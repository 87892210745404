import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/layout';

import * as _404styles from '../styles/404.module.css'

const NotFoundPage = () => {
  return (
    <Layout>
      <main className={_404styles.main}>
        <h1 className={_404styles.header}>404 - Page Not Found</h1>
        <p className={_404styles.description}>The page you were looking for cannot be found</p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
